export const colorCodes: any = [
  "#D9E3F0",
  "#F47373",
  "#697689",
  "#37D67A",
  "#2CCCE4",
  "#555555",
  "#dce775",
  "#ff8a65",
  "#ba68c8",
];
export const OUTLETUSER_EXCEL_HEADER: any[] = ["Outlet Code", "Outlet Name", "City", "Area", "UB Executive Name", "UB Executive Mobile Number"];
export const BARTENDERS_USERS_EXCEL_HEADER: any[] = ["Customer Name", "Mobile Number", "Outlet Code", "User Type"];
export const RESETBANK_EXCEL_HEADER: any[] = ["Mobile Number"];
export const REGION_EXCEL_HEADER: any[] = ["Regions"];
export const STATE_EXCEL_HEADER: any[] = ["States"];
export const CITY_EXCEL_HEADER: any[] = ["Cities"];
export const month_list: any = [
  {
    "abbreviation": "Jan",
    "name": "January",
    id: '01'
  },
  {
    "abbreviation": "Feb",
    "name": "February",
    id: '02'
  },
  {
    "abbreviation": "Mar",
    "name": "March",
    id: '03'
  },
  {
    "abbreviation": "Apr",
    "name": "April",
    id: '04'
  },
  {
    "abbreviation": "May",
    "name": "May",
    id: '05'
  },
  {
    "abbreviation": "Jun",
    "name": "June",
    id: '06'
  },
  {
    "abbreviation": "Jul",
    "name": "July",
    id: '07'
  },
  {
    "abbreviation": "Aug",
    "name": "August",
    id: '08'
  },
  {
    "abbreviation": "Sep",
    "name": "September",
    id: '09'
  },
  {
    "abbreviation": "Oct",
    "name": "October",
    id: '10'
  },
  {
    "abbreviation": "Nov",
    "name": "November",
    id: '11'
  },
  {
    "abbreviation": "Dec",
    "name": "December",
    id: '12'
  }
]
export const regularExpression: any = {
  email: "[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}",
  alphanumeric: '/^[0-9a-zA-Z]+$/',
  noSpecialCharector: '^[0-9a-zA-Z \b]+$',
  latAndLang: '/^[0-9]*\.?[0-9]*$/',
  onlyNumAndSpecialChr: '([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))'
}

export const UNIQUE_CODE_EXCEL_HEADER: any[] = ["Code", "Offer", "Qr Code Url", "Point", "Fifa Ticket"];
export const FIFA_TICKETS_EXCEL_HEADER: any[] = ["Reference Id"];
export const REWARD_VOUCHERS_EXCEL_HEADER: any[] = ["Voucher Code", "Amount", "Brand"];



