import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {


//staging
  //baseUrl = "https://api.heineken.silver.loyalty.bigcityvoucher.co.in/v1";
  //production
  baseUrl = "https://api1.heinekensilversquad.com/v1";




  login = this.baseUrl + "/staff/login";


  //Agent pannel
  listallinvoices = this.baseUrl + "/staff/listallinvoices";
  viewinvoicetransactions = this.baseUrl + "/staff/viewinvoicetransactions";
  uploadinvoicedata = this.baseUrl + "/staff/uploadinvoicedata";
  reasons = this.baseUrl + "/staff/reasons"
  updateinvoicestatus = this.baseUrl + "/staff/updateinvoicestatus";
  downloadInvoiceExcel = this.baseUrl + "/staff/downloadinvoicelist"
  user_bankDetails = this.baseUrl+'/user/customer-details'
  reset_bankDetails =  this.baseUrl+'/user/reset'
  get_filedcashBank = this.baseUrl+'/cashback/get-failed-details'
  retriggerCash = this.baseUrl +'/cashback/cashback-return'


  //masterSetup
  getSatateList = this.baseUrl + "/user/getstatelist";
  getCitiesList = this.baseUrl + "/user/getcitylist";


  //Unique Code
  uniqueCodeList = this.baseUrl + "/vouchers/list";
  uploadUniqueCodeExcel = this.baseUrl + "/vouchers/import";


  //FIFA Tickets
  fifaTicketList = this.baseUrl + "/vouchers/get-ticket";
  uploadFifaTicketExcel = this.baseUrl + "/vouchers/ticket-import";



  //Rewards Vouchers
  rewardVouchersList = this.baseUrl + "/vouchers/get-reward?";
  uploadRewardVouchersExcel = this.baseUrl + "/vouchers/import-reward";

  //Weekly Reports
  getReportsList = this.baseUrl + "/weekly-report/list?";
  downloadWeeklyReports = this.baseUrl + "/weekly-report/export?";
  getweekList = this.baseUrl + "/weekly-report/get-week-config"


  //Weekly Winners
  getRewardByIdList = this.baseUrl + "/reward/getrewardbyweekid?program_id=1";
  getWeeklyDrawWinners = this.baseUrl + "/reward/weeklydrawwinnerannounce?";

  //rewards
  uploadRewardsExcel = this.baseUrl + "/reward/import-rewards?program_id=1";


  //Redemption 
  getOrderList = this.baseUrl + "/redemption/get-order-listings";
  getDownOrderData = this.baseUrl + "/redemption/download-order";
  orderOfferCodeUp = this.baseUrl + "/redemption/order-offer-code-upload";
  changeOrderStatusByForm = this.baseUrl + "/redemption/bulk-status-update?program_id=1";
  changeOrderStatusByeExcel = this.baseUrl + "/redemption/order-statusupload?program_id=1";
  getCancelReasonDropDown = this.baseUrl + "/redemption/get-order-cancelling-reason?program_id=1";



  //Reports
  userReport = this.baseUrl + "/staff/user-report";
  summaryReport = this.baseUrl + "/staff/summary-report";
  skuBillReport = this.baseUrl + "/staff/sku-bill-report";
  masterDataReport = this.baseUrl+"/report/masterdatareport"


  //upload cashback
  uploadCashBack = this.baseUrl + "/staff/upload-cashback-status";
  resetBankDetails = this.baseUrl + "/staff/reset-bankdetails";

  //user Management
  importOutletUser = this.baseUrl + "/staff/uploadoutlets";
  importBarTendersUser = this.baseUrl + "/staff/uploadbartenders";






}
export interface ApiConfigurationInterface { }
