import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './common/signin/signin.component';
import { AuthGuardService } from './services/auth/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'signin',
    pathMatch: 'full',
  },
  {
    path: 'signin',
    component: SigninComponent,
  },
  {
    path: 'hubAdmin',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./hubadmin/hubadmin.module').then(m => m.HubadminModule)
  },
  {
    path: 'agent',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule)
  },
  {
    path: 'reports',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
